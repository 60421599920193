import imgCE from "../../img_react/conteudo_edu.png";
import {useEffect, useState} from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {requestBackend} from "./utils";
function ContentEdu({csrf, planAssigned}){

    const [aulaSelected, setAulaSelected] = useState(null)
    const [urlAulaSelected, setUrlAulaSelected] = useState(null)
    const [modulos, setModulos] = useState([])
    console.log(planAssigned)
    useEffect(() => {
        async function getModulos(){
            const request = await requestBackend('GET','/users/edu_content/', {show_plan_1: planAssigned === 'PLANO 1', show_plan_2: planAssigned === 'PLANO 2'}, csrf)
            const modulos = await request['modules']
            setModulos(modulos)
        }
        getModulos()
    }, []);

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <button type="button" className="btn btn_pattern p-3" onClick={decoratedOnClick}>
            {children}
            </button>
      );
    }

    function AulasInModulo({arrayAulas}){
        function SelectAula(objAula){
            setAulaSelected(objAula.id_aula)
            setUrlAulaSelected(objAula.link)
        }
        return (
            <ul>
                {arrayAulas.map((objAula, index) => (
                    <li onClick={()=>SelectAula(objAula)} className={`${objAula.id_aula === aulaSelected ? 'font_color_green_home' : 'text-white'} cardzinhos_screen_dash roboto-regular font_size_footer cursor_pointer`} key={index}>
                        {objAula.name_aula}
                    </li>
                ))}
            </ul>
        );
    }
    return (
        <div className="container-fluid p-0 height_content margin_top_100">
            <div className="container-fluid p-0">
                <div className="container px-4 d-flex justify-content-center">
                    <div className="container_aulas_ce">
                        {aulaSelected === null ? (
                            <img src={imgCE} className="rounded-4 boder_homezinho_2 w-100 h-100"/>
                        ):
                            (
                                <iframe width="100%" height="100%"
                                        className="rounded-4 boder_homezinho_2" src={urlAulaSelected}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                            )}

                    </div>


                </div>

                <div className="container px-4 d-flex justify-content-center mt-2">
                    <div style={{width: "80%"}}>
                        <div
                            className="col-12 font_color_green_home chakra-petch-regular fw-bold font_size_headers_in_content_home">
                        SEJAM BEM VINDOS.
                        </div>

                        <div className="col-12 roboto-regular font_size_footer mt-3 text-white">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </div>

                        <div className="container-fluid p-0">
                            <Accordion className="my-2">
                                {modulos.map((objModulo, index) => (
                                    <Card key={index} className="border_hidden mt-2" style={{ backgroundColor: 'transparent' }}>
                                        <Card.Header className="d-inline-flex align-items-center border_hidden">
                                            <CustomToggle eventKey={objModulo.id_module}></CustomToggle> <span className="ms-2 text-white font_size_mini_headers_in_content_home chakra-petch-regular">{objModulo.name_module}</span>
                                        </Card.Header>
                                        <Accordion.Collapse className="px-4" eventKey={objModulo.id_module}>
                                          <Card.Body className="border-start"><AulasInModulo arrayAulas={objModulo.aulas}/> </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentEdu