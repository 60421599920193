import {FaListUl} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import { MdViewModule } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";
import {requestBackend} from "../Home/utils";
import { CiYoutube } from "react-icons/ci";
import { MdDriveFileRenameOutline } from "react-icons/md";
function Education({csrf}){
    const [allModules, setAllModules] = useState([])
    const [moduleValue, setModuleValue] = useState('')
    const [showModulePlan1, setShowModulePlan1] = useState(false)
    const [showModulePlan2, setShowModulePlan2] = useState(false)
    const [idModuleEdit, setIdModuleEdit] = useState(null)

    const [idModuleSelected, setIdModuleSelected] = useState(null)
    const [nameModuleSelected, setNameModuleSelected] = useState('')

    const [allVideosInModule, setAllVideosInModule] = useState([])
    const [nameVideoValue, setNameVideoValue] = useState('')
    const [linkVideoValue, setLinkVideoValue] = useState('')
    const [idVideoEdit, setIdVideoEdit] = useState(null)

    useEffect(() => {
        async function getModulos(){
            const request = await requestBackend('GET','/users/modulo/', null, csrf)
            const modulos = await request['modulos']
            setAllModules(modulos)
        }
        getModulos()
    }, []);
    function ModuleExist({objModule}){

        async function SelectModule(){
            setIdModuleSelected(objModule.id)
            setNameModuleSelected(objModule.name)
            const request = await requestBackend('GET','/users/videos_in_modulo/' + objModule.id + '/', null, csrf)
            const videos = await request['allVideos']
            setAllVideosInModule(videos)
        }

        function RemoveSelectionModule(){
            setIdModuleSelected(null)
            setNameModuleSelected('')
            setAllVideosInModule([])
        }
        function BtnModuleSelected(){
            return (
                <button type="button" onClick={RemoveSelectionModule}
                        className="btn btn-secondary w-100 rounded-pill text-start d-inline-flex align-items-center">
                    <MdViewModule size={35} className="me-2"/> {objModule.name}
                </button>
            );
        }

        function BtnModuleNotSelected(){
            return (
                <button type="button" onClick={SelectModule}
                        className="btn btn-outline-secondary w-100 rounded-pill text-start d-inline-flex align-items-center">
                    <MdViewModule size={35} className="me-2"/> {objModule.name}
                </button>
            );
        }

        async function DeleteModule(){
            if(idModuleSelected === objModule.id) {
                setAllVideosInModule([])
                setIdModuleSelected(null)
            }
            setAllModules(allModules.filter(module=>module.id !== objModule.id))
            await requestBackend('DELETE','/users/modulo/' + objModule.id + '/', null, csrf)


        }

        return (
            <div className="row mt-2">
                <div className="col-10">
                    {idModuleSelected === objModule.id ? <BtnModuleSelected/> : <BtnModuleNotSelected/>}
                </div>

                <div className="col-1 d-flex justify-content-center">
                    <button type="button" onClick={()=>ClickBtnEditModule(objModule.id, objModule.name, objModule.show_plan_1, objModule.show_plan_2)} className="btn h-100 btn-outline-secondary w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                        <MdEdit size={35} className=""/>
                    </button>
                </div>

                <div className="col-1 d-flex justify-content-center">
                    <button type="button" onClick={DeleteModule}
                            className="btn h-100 btn-outline-danger w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                        <TiDelete size={35} className=""/>
                    </button>
                </div>
            </div>
        );
    }
    async function SaveModule(){
        if(idModuleEdit === null){
            const searchModuleExists = allModules.filter(objModule=> objModule.name === moduleValue)
            if(!(searchModuleExists.length > 0)){
                const request = await requestBackend('POST','/users/modulo/', {'name': moduleValue,
                    'id': null, 'show_plan_1': showModulePlan1, 'show_plan_2': showModulePlan2}, csrf)
                const newId = await request['id']
                setAllModules([...allModules, {'id': newId, 'name': moduleValue, 'show_plan_1': showModulePlan1, 'show_plan_2': showModulePlan2}])
            }
            else{
                Swal.fire({
                    text: 'Modulo ja existente',
                    icon: "error"
                })
            }
        }
        else{
            const searchNameExists = allModules.filter(objModule=> objModule.name === moduleValue && objModule.id !== idModuleEdit)
            if(!(searchNameExists.length > 0)){
                await requestBackend('POST','/users/modulo/', {'name': moduleValue,
                    'id': idModuleEdit, 'show_plan_1': showModulePlan1, 'show_plan_2': showModulePlan2}, csrf)
                setAllModules(allModules.map(objModule=> objModule.id === idModuleEdit ? {...objModule, name: moduleValue} : objModule))
            }
            else{
                Swal.fire({
                    text: 'Modulo ja existente',
                    icon: "error"
                })
            }
        }
        setModuleValue('')
        setShowModulePlan2(false)
        setShowModulePlan1(false)
        setIdModuleEdit(null)
    }

    function ClickBtnEditModule(idModule, nameModule, showPlan1, showPlan2){
        setModuleValue(nameModule)
        setIdModuleEdit(idModule)
        setShowModulePlan1(showPlan1)
        setShowModulePlan2(showPlan2)
    }


    async function SaveLink(){
        if(idVideoEdit === null){
            const request = await requestBackend('POST','/users/videos_in_modulo/', {'link': linkVideoValue, 'name': nameVideoValue, 'id': null, 'idModulo': idModuleSelected}, csrf)
            const newId = await request['id']
            setAllVideosInModule([...allVideosInModule, {'id': newId, 'name': nameVideoValue, 'link': linkVideoValue}])
        }
        else{
            await requestBackend('POST','/users/videos_in_modulo/', {'link': linkVideoValue, 'name': nameVideoValue, 'id': idVideoEdit, 'idModulo': idModuleSelected}, csrf)
            setAllVideosInModule(allVideosInModule.map(objVideo=> objVideo.id === idVideoEdit ? {...objVideo, name: nameVideoValue, link: linkVideoValue} : objVideo))
        }
        setLinkVideoValue('')
        setNameVideoValue('')
        setIdVideoEdit(null)
    }
    function VideoExists({objVideo}){
        function ClickBtnEditVideo(){
            setIdVideoEdit(objVideo.id)
            setNameVideoValue(objVideo.name)
            setLinkVideoValue(objVideo.link)
        }

        async function DeleteVideo(){
            await requestBackend('DELETE','/users/videos_in_modulo/' + objVideo.id + '/', null, csrf)
            setAllVideosInModule(allVideosInModule.filter(link=>link.id !== objVideo.id))

        }
        return (
            <div className="row mt-2">
                <div className="col-10">
                    <button type="button" className="btn btn-outline-secondary w-100 rounded-pill">
                        <div className="row">
                            <div className="col-4 d-inline-flex align-items-center justify-content-start text-truncate">
                                <MdDriveFileRenameOutline size={35} className="me-2"/> {objVideo.name}
                            </div>

                            <div className="col-8 text-truncate">
                                <CiYoutube size={35} className="me-2"/> {objVideo.link}
                            </div>
                        </div>

                    </button>
                </div>

                <div className="col-1 d-flex justify-content-center">
                    <button type="button" onClick={ClickBtnEditVideo} className="btn h-100 btn-outline-secondary w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                        <MdEdit size={35} className=""/>
                    </button>
                </div>

                <div className="col-1 d-flex justify-content-center">
                    <button type="button" onClick={DeleteVideo}
                            className="btn h-100 btn-outline-danger w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                        <TiDelete size={35} className=""/>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Gerenciar Conteudo Educacional
            </div>
            <div className="container-fluid rounded-3">
                <div className="row">
                    <div className="col-6">
                        <div className="card bg_login rounded-4">
                            <div className="card-header font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                                Modulos
                            </div>
                            <div className="card-body roboto-regular">
                                <div className="container-fluid p-0">
                                    <div className="col-12">
                                        <div className="form-floating ">
                                            <input type="text" defaultValue={moduleValue} onChange={(e)=>setModuleValue(e.target.value)} className="form-control" id="floatingInput"/>
                                            <label htmlFor="floatingInput">Nome do Modulo:</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                   onChange={(e) => setShowModulePlan1(e.target.checked)}
                                                   checked={showModulePlan1} type="checkbox" id="showPlan1"/>
                                            <label className="form-check-label text-white" htmlFor="showPlan1">
                                                Exibir no Plano 1
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                   onChange={(e) => setShowModulePlan2(e.target.checked)}
                                                   checked={showModulePlan2} type="checkbox" id="showPlan2"/>
                                            <label className="form-check-label text-white" htmlFor="showPlan2">
                                                Exibir no Plano 2
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-12 d-flex justify-content-end mt-2">
                                        <button className="btn btn_pattern w-auto rounded-4" type="button"
                                                onClick={SaveModule}>
                                            Salvar
                                        </button>
                                    </div>

                                    {allModules.length > 0 && (
                                        <div className="card border bg_login font_color_green_home mt-2 p-2">
                                            {allModules.map((objModule, index) => (
                                                <ModuleExist objModule={objModule} key={index}/>
                                            ))}
                                        </div>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="card bg_login rounded-4">
                            <div
                                className="card-header font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                                Videos no Modulo
                            </div>
                            {idModuleSelected !== null && (
                                <div className="card-body roboto-regular">
                                    <div className="col-12 font_color_green_home">
                                        Modulo selecionado: <small
                                        className="py-0 px-2 h-auto bg-success-subtle text-success-emphasis rounded-2">{nameModuleSelected}</small>
                                    </div>

                                    <div className="container-fluid p-0">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-floating ">
                                                    <input type="text" defaultValue={nameVideoValue}
                                                           onChange={(e) => setNameVideoValue(e.target.value)}
                                                           className="form-control" id="floatingInput"/>
                                                    <label htmlFor="floatingInput">Nome do video:</label>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="form-floating">
                                                    <input type="text" defaultValue={linkVideoValue}
                                                           onChange={(e) => setLinkVideoValue(e.target.value)}
                                                           className="form-control" id="floatingInput"/>
                                                    <label htmlFor="floatingInput">Link do video:</label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12 d-flex justify-content-end mt-2">
                                            <button className="btn btn_pattern w-auto rounded-4" type="button" onClick={SaveLink}>
                                                Salvar
                                            </button>
                                        </div>

                                        {allVideosInModule.length > 0 && (
                                            <div className="card border bg_login font_color_green_home mt-2 p-2">
                                                {allVideosInModule.map((objVideo, index) => (
                                                    <VideoExists objVideo={objVideo} key={index}/>
                                                ))}
                                            </div>
                                        )}


                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Education