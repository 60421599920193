import React, {useEffect, useState} from "react";
import {requestBackend} from "./utils";
import {Spinner} from "./utils";

function MeuPlano({csrf, planAssigned}){
    const [dateFinishPlan, setDateFinishPlan] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        async function getDateFinishPlan() {
            const request = await requestBackend('GET', '/users/date_finish_plan_assinged/', null, csrf)
            const dateFinish = await request['dateFinish']
            setDateFinishPlan(dateFinish)
            setShowSpinner(false)
        }

        setShowSpinner(true)
        getDateFinishPlan()
    }, []);

    function RealNamePlan(){
        return planAssigned === 'PLANO 1' ? 'SuperQuant Full' : 'SuperQuant Extended'
    }
    return (
        <div className="container-fluid height_content margin_top_100">
            <div className="container h-100 text-white">
                {showSpinner ? <Spinner/> : (
                    <div className="col-12 text-center text-white chakra-petch-regular fw-bold font_size_mini_headers_in_content_home">
                        {dateFinishPlan === null ? <>Nenhum plano ativo</>:
                            (<>{RealNamePlan()} está ativo até {dateFinishPlan}</>)
                        }


                    </div>
                )}
            </div>
        </div>
    );
}

export default MeuPlano