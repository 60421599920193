import React from "react";

function Footerzada(){
    return (
        <div className="container-fluid p-0 bg-white pt-5 mt-5 roboto-regular">
            <div className="row d-flex justify-content-center">
                <div className="col-9 font_size_footer">
                    <b>AVISO LEGAL:</b><br/>
                    Investir no mercado financeiro envolve riscos significativos, incluindo a perda de capital.
                    Antes de tomar qualquer decisão de investimento, é essencial entender os riscos envolvidos e
                    tomar precauções. Informe-se sobre os diferentes tipos de investimentos, seus riscos e
                    retornos esperados. Leia materiais educativos, participe de seminários e siga fontes
                    confiáveis de informação financeira. Diversifique seus investimentos para reduzir o risco,
                    evitando concentrar todo seu capital em um único ativo ou setor. Realize sua própria análise
                    e não se baseie apenas em recomendações de terceiros. Utilize ferramentas de rastreamento e
                    análise, como o <b>SuperQuant</b>, para obter insights, mas sempre avalie se as
                    oportunidades são adequadas ao seu perfil de investidor.
                    <br/>
                    <br/>
                    Conheça seu perfil de investidor e invista conforme sua tolerância ao risco, considerando
                    fatores como idade, objetivos financeiros, horizonte de investimento e necessidade de
                    liquidez. Considere consultar um consultor financeiro ou gestor de investimentos para
                    orientações personalizadas e para ajudar a estruturar um portfólio que atenda às suas
                    necessidades e objetivos. O mercado financeiro é dinâmico e sujeito a mudanças rápidas, por
                    isso mantenha-se atualizado com notícias econômicas e financeiras, revisando periodicamente
                    suas estratégias e portfólio. Estabeleça limites claros para perdas e ganhos, utilizando
                    estratégias como stop-loss e take-profit, e monitore regularmente seus investimentos,
                    ajustando conforme necessário.
                    <br/>
                    <br/>
                    As informações fornecidas pelo <b>SuperQuant</b> são baseadas em análises de dados e leitura
                    de mercado, mas não garantem resultados específicos. Toda decisão de investimento deve ser
                    tomada com cautela e responsabilidade. O desempenho passado não é indicativo de resultados
                    futuros. Invista de maneira consciente e responsável. O <b>SuperQuant</b> está aqui para
                    ajudar a identificar oportunidades, mas o controle final e a responsabilidade sobre os
                    investimentos são sempre seus.
                </div>
            </div>
            <div className="col-12 text-center font_size_footer mt-5">
                Copyright © 2024 Superquant.com.br
            </div>
        </div>
    );
}

export default Footerzada