import EditUser from "../EditUser";
import Footerzada from "./Footerzada";

function EditUserInHome({csrf, idUserLogged}){
    return (
        <>
            <div className="container-fluid height_content margin_top_100">
                <div className="container h-100 text-white">
                    <div
                        className="col-10 px-5 py-5 mt-5 text-white chakra-petch-regular fw-bold font_size_mini_headers_in_content_home">
                        Meus dados
                    </div>
                    <EditUser csrf={csrf} idUser={idUserLogged}/>
                </div>
            </div>
            <Footerzada/>
        </>

    );
}

export default EditUserInHome