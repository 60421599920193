import React, {useEffect, useState} from "react";
import {requestBackend} from "../Home/utils";
import Swal from "sweetalert2";

function LinkYtb({csrf}){
    const [urlRegister, setUrlRegister] = useState('')
    useEffect(() => {
        async function getLinkExists(){
            const request = await requestBackend('GET','/users/link_ytb/', null, csrf)
            setUrlRegister(await request['link'])
        }
        getLinkExists()
    }, []);
    async function RegisterLink(){
        if(urlRegister.length > 0){
            await requestBackend('POST', '/users/link_ytb/', {link: urlRegister}, csrf)
            Swal.fire({
                text: "Link Salvo!!",
                icon: "success"
            })
        }
        else{
            Swal.fire({
                text: "Digite algo",
                icon: "error"
            })
        }
    }
    return (
        <div className="container-fluid p-3">

            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Url do Youtube no Dash de Ações
            </div>

            <div className="col-12">
                <div className="form-floating">
                    <input type="text" value={urlRegister}
                           onChange={(e) => setUrlRegister(e.target.value)} className="form-control"
                           id="urlRegister"/>
                    <label htmlFor="urlRegister">Url:</label>
                </div>
            </div>

            <div className="col-12 d-flex justify-content-end mt-2">
                <button className="btn btn_pattern w-auto rounded-4" type="button" onClick={RegisterLink}>
                    Salvar
                </button>
            </div>
        </div>
    );
}

export default LinkYtb