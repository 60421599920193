import {useState} from "react";
import Swal from "sweetalert2";
import {requestBackend} from "./utils";
import Footerzada from "./Footerzada";
function Contato({csrf}){
    const [name, setName] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    async function RegisterContatoMessage(){
        if(name.length > 0 && telefone.length > 0 && email.length > 0 && message.length > 0){
            await requestBackend('POST','/users/contato_messages/',{'datasMessage': {'name': name,
                'fone': telefone, 'email': email, 'message': message}}, csrf)
            Swal.fire({
                "text": "Sua Mensagem foi enviada!",
                "icon": "success"
            })
            ClearForms()
        }
        else{
            Swal.fire({
                "text": "Preencha todos os campos!!",
                "icon": "error"
            })
        }
    }

    function ClearForms(){
        setName('')
        setTelefone('')
        setEmail('')
        setMessage('')
    }
    return (
        <>
            <div className="container-fluid height_content margin_top_100">
                <div className="container ">
                    <div className="row d-flex justify-content-center">
                        <div
                            className="col-10 mt-5 text-white chakra-petch-regular fw-bold font_size_mini_headers_in_content_home">
                            Formulário para contato
                        </div>

                        <div className="col-10 mt-5">
                            <div className="form-floating">
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                       className="form-control" id="contatoName" placeholder="name@example.com"/>
                                <label htmlFor="contatoName">Nome Completo</label>
                            </div>
                        </div>

                        <div className="col-10 mt-5">
                            <div className="form-floating">
                                <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)}
                                       className="form-control" id="contatoFone" placeholder="name@example.com"/>
                                <label htmlFor="contatoFone">Telefone</label>
                            </div>
                        </div>

                        <div className="col-10 mt-5">
                            <div className="form-floating">
                                <input type="email" value={email} className="form-control"
                                       onChange={(e) => setEmail(e.target.value)} id="contatoEmail"
                                       placeholder="name@example.com"/>
                                <label htmlFor="contatoEmail">Email</label>
                            </div>
                        </div>

                        <div className="col-10 mt-5">
                            <div className="form-floating">
                                <textarea className="form-control" value={message}
                                          onChange={(e) => setMessage(e.target.value)} id="contatoMessage" rows="5"
                                          placeholder="name@example.com"/>
                                <label htmlFor="contatoMessage">Mensagem</label>
                            </div>
                        </div>

                        <div className="col-10 mt-5 d-flex justify-content-center">
                            <button onClick={RegisterContatoMessage}
                                    className="btn btn_contato fw-bold text-white py-2 px-5 rounded-4">
                                ENVIAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footerzada/>
        </>

    );
}

export default Contato