import {cpfValid, emailValid, foneValid, formatarCPF, formatarNumeroTelefone, passwordForce} from "./Home/Validators";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faIdCard} from "@fortawesome/free-solid-svg-icons/faIdCard";
import {faMobileRetro} from "@fortawesome/free-solid-svg-icons/faMobileRetro";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import {requestBackend} from "./Home/utils";
import Swal from "sweetalert2";

function EditUser({csrf, idUser}){
    const [formName, setFormName] = useState({value: '', classForm: ''})
    const [formSecondName, setFormSecondName] = useState({value: '', classForm: ''})
    const [formCpf, setFormCpf] = useState({value: '', classForm: ''})
    const [formEmail, setFormEmail] = useState({value: '', classForm: ''})
    const [formFone, setFormFone] = useState({value: '', classForm: ''})

    useEffect(() => {
        async function getDatasUser(){
            const request = await requestBackend('GET','/users/update_user/' + idUser + '/', null, csrf)
            const datasUser = await request['datasUser']
            ChangeNome(datasUser.name)
            ChangeSobrenome(datasUser.second_name)
            ChangeCpf(datasUser.cpf)
            ChangeEmail(datasUser.email)
            ChangePhone(datasUser.fone)
        }
        getDatasUser()
    }, []);

    function ChangeCpf(cpfValue){
        const valid = cpfValid(cpfValue)
        const classValid = !valid ? 'is-invalid' : 'is-valid'
        setFormCpf({value: formatarCPF(cpfValue), classForm: classValid})
    }

    function ChangeEmail(emailValue){
        const emailOk = emailValid(emailValue)
        const classValid = emailOk ? 'is-valid' : 'is-invalid'
        setFormEmail({value: emailValue, classForm: classValid})
    }

    function ChangeNome(nomeValue){
        const classValid = nomeValue.length === 0 ? 'is-invalid' : 'is-valid'
        setFormName({value: nomeValue, classForm: classValid})
    }

    function ChangeSobrenome(sobrenomeValue){
        const classValid = sobrenomeValue.length === 0 ? 'is-invalid' : 'is-valid'
        setFormSecondName({value: sobrenomeValue, classForm: classValid})
    }

    function ChangePhone(phoneValue){
        const classValid = foneValid(phoneValue) ? 'is-valid': 'is-invalid'
        const format= formatarNumeroTelefone(phoneValue)
        setFormFone({value: format, classForm: classValid})
    }

    function StateFormOk(state){
        return !(state.classForm === "is-invalid" || state.classForm === "")
    }

    function VerifyDatasFormsOk(){
        const nomeOk = StateFormOk(formName)
        const sobrenomeOk = StateFormOk(formSecondName)
        const cpfOk = StateFormOk(formCpf)
        const phoneOk = StateFormOk(formFone)
        const emailOk = StateFormOk(formEmail)
        return nomeOk && sobrenomeOk && cpfOk && phoneOk && emailOk
    }

    async function SaveDatasUserEdit(){
        const formsOk = VerifyDatasFormsOk()
        if(formsOk){
            const datasRequest = await requestBackend('POST','/users/update_user/',
                        {'idUser': idUser, 'nome': formName.value, 'sobrenome': formSecondName.value,
                            'email': formEmail.value, 'fone': formFone.value, 'cpf': formCpf.value}, csrf)
            let datasReturn = await datasRequest['datas']
            Swal.fire({
                'text': datasReturn['msg_return'],
                'icon': !datasReturn.error ? 'success' : 'error'
            })

        }
    }
    return (
        <div className="container-fluid font_color_navmenu d-flex align-content-center py-5 px-5">
            <div className="row g-3 animate__animated animate__backInLeft">
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                    <label>Nome:<span className="text-primary">*</span></label>
                    <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                        <input type="text" className={`form-control z-index-0 ${formName.classForm}`}
                               placeholder="Digite seu nome"
                               defaultValue={formName.value}
                               onChange={(e) => ChangeNome(e.target.value)}/>
                    </div>
                </div>

                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-2">
                    <label>Sobrenome:<span className="text-primary">*</span></label>
                    <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faUsers}/>
                        </div>
                        <input type="text" className={`form-control z-index-0 ${formSecondName.classForm}`}
                               placeholder="Digite seu sobrenome"
                               defaultValue={formSecondName.value}
                               onChange={(e) => ChangeSobrenome(e.target.value)}/>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                    <label>CPF:<span className="text-primary">*</span></label>
                    <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faIdCard}/>
                        </div>
                        <input type="text" className={`form-control z-index-0 ${formCpf.classForm}`}
                               placeholder="000.000.000-00" value={formCpf.value}
                               onChange={(e) => ChangeCpf(e.target.value)}/>
                    </div>
                </div>

                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-2">
                    <label>Telefone:<span className="text-primary">*</span></label>
                    <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon
                            icon={faMobileRetro}/></div>
                        <input type="text" className={`form-control z-index-0 ${formFone.classForm}`}
                               placeholder="(00) 00000-0000" value={formFone.value}
                               onChange={(e) => ChangePhone(e.target.value)}/>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                    <label>Email<span className="text-primary">*</span></label>
                    <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/>
                        </div>
                        <input type="text" className={`form-control z-index-0 ${formEmail.classForm}`}
                               placeholder="Digite seu e-mail" defaultValue={formEmail.value}
                               onChange={(e) => ChangeEmail(e.target.value)}/>
                    </div>
                </div>


                <div className="col-12">
                    <button className="btn btn_pattern fw-bold px-4 float-end mt-4" onClick={SaveDatasUserEdit}>
                        Salvar <FontAwesomeIcon icon={faRightToBracket}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditUser