import { Modal } from 'react-bootstrap';
import EditUser from "../EditUser";
function ModalEditUser({modalShow, setModalShow, idUserSelected, setIdUserSelected, csrf}){
    function CloseModal(){
        setIdUserSelected(null)
        setModalShow(false)
    }
    return (
        <Modal size="lg" show={modalShow} onHide={CloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Editar usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {idUserSelected !== null && <EditUser idUser={idUserSelected} csrf={csrf}/>}
          </Modal.Body>
        </Modal>
    );
}

export default ModalEditUser