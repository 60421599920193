import { TiDelete } from "react-icons/ti";
import {useEffect, useState} from "react";
import {requestBackend} from "../Home/utils";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import ModalEditUser from "./ModalEditUser";
import ModalPlanUser from "./ModalPlanUser";
import { MdDelete } from "react-icons/md";
import {ModalConfirm} from "../Home/utils";
import Swal from "sweetalert2";
function AllUsers({csrf}){
    function TableUsers(){
        const [allUsers, setAllUsers] = useState([])
        const [idUserEdit, setIdUserEdit] = useState(null)
        const [showModalEdit, setShowModalEdit] = useState(false)
        const [idUserPlan, setIdUserPlan] = useState(null)
        const [showModalPlan, setShowModalPlan] = useState(false)
        const [showModalConfirm, setShowModalConfirm] = useState(false)
        const [idUserDelete, setIdUserDelete] = useState(null)
        const [valueFilterTable, setValueFilterTable] = useState('')

        useEffect(() => {
            async function getUsers(){
                const request = await requestBackend('GET','/users/all_users/', null, csrf)
                const users = await request['allUsers']
                setAllUsers(users)
            }
            getUsers()
        }, []);

        function CloseModalConfirm(){
            setShowModalConfirm(false)
            setIdUserDelete(null)
        }
        function RowTable({objUser}){
            const [stateUser, setStateUser] = useState(objUser)

            function ReturnStatusUser(){
                if(stateUser.status === 'Ativo'){
                    return (
                        <div className="px-2 font_size_footer bg-opacity-25 bg-success text-success rounded-4 w-auto">
                            <RiVerifiedBadgeFill className="me-2 mb-1" size={20}/>Ativo</div>
                    );
                }
                else{
                    return (
                        <div className="px-2 font_size_footer bg-opacity-25 bg-danger text-danger rounded-4 w-auto">
                            <TiDelete className="me-2 mb-1" size={20}/>Inativo</div>
                    );
                }
            }

            function EditUser(){
                setIdUserEdit(stateUser.id)
                setShowModalEdit(true)
            }

            function PlanUser(){
                setIdUserPlan(stateUser.id)
                setShowModalPlan(true)
            }
            async function UpdateStatusUser(newStatus){
                // requisitar back-end pra atualizar
                await requestBackend('POST', '/users/all_users/', {idUser: stateUser.id, status: newStatus}, csrf)
                setStateUser({...stateUser, status: newStatus})
            }

            function ClickBtnDelUser(){
                setIdUserDelete(stateUser.id)
                setShowModalConfirm(true)
            }

            function ReturnActionStatusUser(){
                if(stateUser.status === 'Inativo'){
                    return (
                        <div className="px-2 cursor_pointer font_size_footer bg-opacity-25 bg-success text-success rounded-4" onClick={()=>UpdateStatusUser('Ativo')}>
                            <RiVerifiedBadgeFill className="me-1 mb-1" size={20}/>Ativar</div>
                    );
                }
                else{
                    return (
                        <div className="px-2 cursor_pointer font_size_footer bg-opacity-25 bg-danger text-danger rounded-4" onClick={()=>UpdateStatusUser('Inativo')}>
                            <TiDelete className="me-1 mb-1" size={20}/>Inativar</div>
                    );
                }
            }

            return (
                <tr>
                    <td>
                        <div className="form-check">
                            <input className="form-check-input" readOnly={true} type="checkbox" checked={stateUser.checked}/>
                        </div>
                    </td>
                    <td>{stateUser.name}</td>
                    <td>{stateUser.email}</td>
                    <td className="text-center">{stateUser.fone}</td>
                    <td className="text-center"><ReturnStatusUser/></td>
                    <td className="d-flex justify-content-center">
                        <ReturnActionStatusUser/>
                        <div onClick={EditUser}
                             className="px-2 ms-1 cursor_pointer font_size_footer bg-opacity-25 bg-primary text-primary rounded-4">
                            <FaEdit className="me-1 mb-1" size={20}/>Editar
                        </div>
                        <div onClick={PlanUser}
                             className="px-2 ms-1 cursor_pointer font_size_footer bg-opacity-25 bg-warning text-black rounded-4">
                            <MdOutlineAttachMoney className="me-1 mb-1" size={20}/>Plano
                        </div>
                        <div onClick={ClickBtnDelUser} className="px-2 ms-1 cursor_pointer font_size_footer bg-opacity-25 bg-danger text-danger rounded-4">
                            <MdDelete className="me-1 mb-1" size={20}/>Excluir
                        </div>
                    </td>
                </tr>
            );
        }

        async function DeleteUser(){
            await requestBackend('DELETE', '/users/delete_user/' + idUserDelete + "/", null, csrf)
            Swal.fire({
                'text': 'Usuário removido',
                'icon': 'success'
            })
            setAllUsers(allUsers.filter(user=>user.id !== idUserDelete.id))
        }

        return (
            <div className="container-fluid p-3">
                <div className="row">
                    <div className="col-8 d-flex align-items-center font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                        Gerenciar Usuários
                    </div>

                    <div className="col-4">
                        <div className="input-group mb-3">
                          <span className="input-group-text"><FaFilter/></span>
                          <input type="text" value={valueFilterTable} onChange={(e)=>setValueFilterTable(e.target.value)} className="form-control" placeholder="Pesquise por um nome..."/>
                        </div>

                    </div>
                </div>

                <table className="table table-hover">
                    <thead>
                    <tr className="chakra-petch-regular">
                    <th className="d-flex align-items-center">
                            <div className="form-check">
                                <input className="form-check-input" readOnly={true} type="checkbox" value=""
                                       id="flexCheckDefault"/>
                            </div>
                        </th>
                        <th>Nome Completo</th>
                        <th>Email</th>
                        <th className="text-center">Telefone</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allUsers.filter(user=>user.name.toUpperCase().includes(valueFilterTable.toUpperCase())).map((objUser, index) => (
                        <RowTable objUser={objUser} key={index}/>
                    ))}

                    </tbody>
                </table>
                <ModalConfirm onConfirm={DeleteUser} header={'Remover Usuário'}
                              message={'Tem certeza que deseja remover ele ?'} onHide={CloseModalConfirm}
                              show={showModalConfirm}/>
                <ModalEditUser idUserSelected={idUserEdit} setIdUserSelected={setIdUserEdit} csrf={csrf}
                               modalShow={showModalEdit} setModalShow={setShowModalEdit}/>
                <ModalPlanUser idUserSelected={idUserPlan} setIdUserSelected={setIdUserPlan} csrf={csrf}
                               modalShow={showModalPlan} setModalShow={setShowModalPlan}/>
            </div>

        );
    }

    return (
        <TableUsers/>
    );
}

export default AllUsers