import ContainerBaseHome from "./Home";
import ContentLogin from "./Login";
import Dashboard from "./Dashboard";
import Assine from "./Assine";
import Carteira from "./Carteira";
import bgImg from "../../img_react/bg_home.svg";
import logoNav from "../../img_react/logo_navmenu.svg";
import React, {useEffect, useState} from "react";
import '../../css_react/home.css';
import 'animate.css';
import {getCsrfToken, requestBackend, userLogged, getMenuSelected} from "./utils";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ContentEdu from "./ContentEdu";
import Contato from "./Contato";
import EditUserInHome from "./EditUserInHome";
import MeuPlano from "./MeuPlano";
import {Spinner} from "./utils";
import { AiOutlineBars } from "react-icons/ai";
function AppHome(){
    const [menuSelected, setMenuSelect] = useState('Home')
    const [Logged, setLogged] = useState(false)
    const [planAssigned, setPlanAssigned] = useState(null)
    const [showSpinner, setShowSpinner] = useState(true)
    const [idUserLogged, setIdUserLogged] = useState(null)
    let csrfToken = null

    useEffect(()=> {
        const getCsrf = async () => {
            csrfToken = await getCsrfToken()
        }

        const funcLogged = async () => {
            const userInfo = await userLogged()
            setLogged(userInfo.userLogged)
            setPlanAssigned(userInfo.planAssigned)
            setIdUserLogged(userInfo.idUserLogged)
            setShowSpinner(false)
        }

        const gambiarraMenu = async () => {
            const menu = await getMenuSelected()
            if(menu !== null){
                setMenuSelect(menu)
            }
        }

        getCsrf()
        funcLogged()
        gambiarraMenu()
    }, [])
    function NavMenu(props){

        function ReturnClassActive(menuVerify){
            const menuParam = props.menu
            return menuVerify === menuParam ? 'active':''
        }

        function ButtonUserLogged(){

            async function LogoutUser(){
                await requestBackend('GET','/users/logout_user/', null, null)
                setLogged(false)
                setMenuSelect('Home')
            }

            return (
                <div className="btn-group">
                  <button type="button" className="text-white btn bg_green_pattern_home chakra-petch-regular"  onClick={() => setMenuSelect('Carteira')}>Perfil <FontAwesomeIcon icon={faUser} className="ps-2"/></button>
                  <button type="button" className="btn bg_green_pattern_home dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">aaa</span>
                  </button>
                  <ul className="dropdown-menu px-2 bg_green_pattern_home">
                    <li><a className="dropdown-item bg_login rounded-pill text-white text-start chakra-petch-regular" onClick={LogoutUser}>Sair <FontAwesomeIcon icon={faRightToBracket} className="ps-2"/></a></li>
                  </ul>
                </div>
            );
        }

        function ButtonLogin(){
            if(Logged){
                return (<ButtonUserLogged/>);
            } else {
                return (<button type="button" className="text-white btn bg_green_pattern_home chakra-petch-regular"
                                onClick={() => setMenuSelect('Login')}>Login</button>);
            }
        }

        return (
            <nav className="navbar navbar-expand-lg z-index-1000 border_bot_navmenu position-fixed w-100 chakra-petch-regular" style={{backgroundImage: `url(${bgImg})`, backgroundSize: "cover"}}>
                <div className="container">
                    <a className="navbar-brand w-25" href="/"><img src={logoNav} height={70}/></a>
                    <button className="navbar-toggler text-white border border-white" type="button" data-bs-toggle="collapse" data-bs-target="#navBarHome" aria-controls="navBarHome" aria-expanded="false" aria-label="Toggle navigation">
                        <AiOutlineBars/>
                    </button>
                    <div className="collapse navbar-collapse" id="navBarHome">
                        <ul className="navbar-nav w-100 d-flex justify-content-around">
                            <li className="nav-item cursor_pointer">
                                <a className={`nav-link text-white ${ReturnClassActive('Home')}`} onClick={()=> setMenuSelect('Home')}>Home</a>
                            </li>
                            <li className="nav-item cursor_pointer">
                                <a className={`nav-link text-white ${ReturnClassActive('Dashboard')}`} onClick={()=> setMenuSelect('Dashboard')}>Dashboard</a>
                            </li>

                            <li className="nav-item cursor_pointer">
                                <a className={`nav-link text-white ${ReturnClassActive('Assine')}`} onClick={()=> setMenuSelect('Assine')}>Assine</a>
                            </li>

                            <li className="nav-item cursor_pointer">
                                <a className={`nav-link text-white ${ReturnClassActive('Contato')}`} onClick={()=> setMenuSelect('Contato')}>Contato</a>
                            </li>

                            <li className="nav-item cursor_pointer">
                                <ButtonLogin/>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    function ReturnContentHome(){

        if(menuSelected === 'Home'){
            return <ContainerBaseHome csrf={csrfToken} setMenu={setMenuSelect}/>
        }
        else if(menuSelected === 'Dashboard'){
            return <Dashboard csrf={csrfToken} setMenu={setMenuSelect} planAssigned={planAssigned}/>
        }
        else if(menuSelected === "Login"){
            return <ContentLogin csrf={csrfToken} setPlanAssigned={setPlanAssigned} setMenu={setMenuSelect} setUserLogged={setLogged} setIdUserLogged={setIdUserLogged}/>
        }
        else if(menuSelected === "Carteira"){
            return <Carteira csrf={csrfToken} setMenu={setMenuSelect}/>
        }
        else if(menuSelected === "Assine"){
            return <Assine csrf={csrfToken} setMenu={setMenuSelect} userLogged={Logged}/>
        }
        else if (menuSelected === "Edu"){
            return <ContentEdu csrf={csrfToken} planAssigned={planAssigned}/>
        }
        else if (menuSelected === "Datas"){
            return <EditUserInHome idUserLogged={idUserLogged} csrf={csrfToken}/>
        }
        else if(menuSelected === 'MeuPlano'){
            return <MeuPlano planAssigned={planAssigned} csrf={csrfToken}/>
        }
        else{
            return <Contato csrf={csrfToken}/>
        }
    }

    return (
        <GoogleOAuthProvider clientId="761740692155-2qumkp02cm1kl73g7rl6vd1qbd003bd9.apps.googleusercontent.com">
            <div className="position-absolute w-100 h-100 overflow_auto overflow-x-none" style={{backgroundImage: `url(${bgImg})`, backgroundSize: "cover"}}>
                <NavMenu menu={menuSelected}/>
                {showSpinner && <Spinner/>}
                <ReturnContentHome/>
            </div>
        </GoogleOAuthProvider>
    );
}

export default AppHome