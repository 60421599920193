import React, {useEffect, useState} from "react";
import {AiTwotoneEyeInvisible} from "react-icons/ai";
import Toast from "react-bootstrap/Toast";
import {requestBackend} from "../Home/utils";
import {MdDriveFileRenameOutline} from "react-icons/md";
import {CiYoutube} from "react-icons/ci";
import {TiDelete} from "react-icons/ti";
import { SiMicrostrategy } from "react-icons/si";
import Swal from "sweetalert2";

function MessagesAcoes({csrf}){
    const [allMessages, setAllMessages] = useState([])
    const [allStrategys, setAllStrategys] = useState([])
    const [allAtivos, setAllAtivos] = useState([])

    useEffect(() => {
        async function getMsg(){
            const request = await requestBackend('GET','/users/all_msgs/', null, csrf)
            setAllMessages(await request['allMsg'])
        }
        async function getAtivos(){
            const request = await requestBackend('GET','/users/all_ativos_msg/', null, csrf)
            setAllAtivos(await request['arrayAtivos'])
        }
        async function getStrategys(){
            const request = await requestBackend('GET','/users/all_strategys/', null, csrf)
            setAllStrategys(await request['arrayStrategys'])
        }

        getStrategys()
        getAtivos()

        getMsg()
    }, []);

    function RowCreateNewMsg(){
        const [typeMessage, setTypeMessage] = useState(null)


        async function PostCreateMessage(message, header){
            const request = await requestBackend('POST','/users/all_msgs/', {'msg': message, 'typeMessage': typeMessage, 'header': header}, csrf)
            const objReturn = await request['return']
            setAllMessages([...allMessages, {'msg': message, 'id': objReturn.id, 'date': objReturn.date}])

        }
        function ReturnContentCreateMessage(){
            if(typeMessage === "OPÇÃO"){
                return <CreationMessageEstrategy/>
            }
            else{
                return <CreationMessageAtivoInfo/>;
            }
        }

        function CreationMessageAtivoInfo(){
            const [compraVenda, setCompraVenda] = useState('COMPRA')
            const [ativoSelected, setAtivoSelected] = useState('0')
            const [codigo, setCodigo] = useState('')
            const [priceNow, setPriceNow] = useState('')
            const [priceBase, setPriceBase] = useState('')
            const [priceAlvo, setPriceAlvo] = useState('')

            async function AddMessage(){
                if(!(ativoSelected === '0' || codigo.length === 0 || priceNow.length === 0 || priceBase.length === 0 || priceAlvo.length === 0)){
                    const messageInHtml = `
                    <b>${ativoSelected} - ${codigo} -</b> Zona do GPO <br/>
                    Preço Atual: ${priceNow}<br/>
                    Preço Base: ${priceBase}<br/>
                    Preço Alvo: ${priceAlvo}<br/>
                    ATENÇÃO: Validar a entrada pelo GPO.
                    `
                    await PostCreateMessage(messageInHtml, ativoSelected)
                }
                else{
                    Swal.fire({
                        "text": "Preencha todos os campos para adicionar uma mensagem",
                        "icon": "error"
                    })
                }
            }
            return (
                <div className="container-fluid p-0">
                    <div className="col-12">
                        <div className="form-floating">
                            <select className="form-select" onChange={(e) => setCompraVenda(e.target.value)}
                                    value={compraVenda} id="compraVendaCreateMessage">
                                <option value="COMPRA">COMPRA</option>
                                <option value="VENDA">VENDA</option>
                            </select>
                            <label htmlFor="compraVendaCreateMessage">COMPRA/VENDA</label>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-select" value={ativoSelected}
                                        onChange={(e) => setAtivoSelected(e.target.value)} id="ativoCreateMessage">
                                    <option value="0">---</option>
                                    {allAtivos.map((objAtv, index) => (
                                        <option value={objAtv.name} key={index}>{objAtv.name}</option>
                                    ))}
                                </select>
                                <label htmlFor="ativoCreateMessage">Ativo</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)}
                                       className="form-control" id="codigoCreateMessage"/>
                                <label htmlFor="codigoCreateMessage">Timeframe</label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" value={priceNow}
                                       onChange={(e) => setPriceNow(e.target.value)} className="form-control"
                                       id="priceNowCreateMessage"/>
                                <label htmlFor="priceNowCreateMessage">Preço Atual</label>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" value={priceBase}
                                       onChange={(e) => setPriceBase(e.target.value)} className="form-control"
                                       id="priceBaseCreateMessage"/>
                                <label htmlFor="priceBaseCreateMessage">Preço Base</label>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" value={priceAlvo}
                                       onChange={(e) => setPriceAlvo(e.target.value)} className="form-control"
                                       id="priceAlvoCreateMessage"/>
                                <label htmlFor="priceAlvoCreateMessage">Preço Alvo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 my-2 d-flex justify-content-center">
                        <button className="btn btn_pattern rounded-4" onClick={AddMessage} type="button">
                            Criar Mensagem
                        </button>
                    </div>
                </div>
            );
        }

        function CreationMessageEstrategy() {
            const [strategySelected, setStrategySelected] = useState('0')
            const [ativoSelected, setAtivoSelected] = useState('0')
            const [valueAtivo, setValueAtivo] = useState('')
            const [pmValue, setPmValue] = useState('')
            const [validade, setValidade] = useState('')

            const [compraVenda, setCompraVenda] = useState('COMPRA')
            const [tipo, setTipo] = useState('COMPRA')
            const [codigo, setCodigo] = useState('')
            const [valuePutCall, setValuePutCall] = useState('')
            const [arrayPutsCalls, setArrayPutsCalls] = useState([])

            function CreateHtmlPutCall(){
                let contentHtml = ``
                for (let i = 0; i < arrayPutsCalls.length; i++) {
                    const objPc = arrayPutsCalls[i]
                    contentHtml += `<b>${objPc.compraVenda} ${objPc.tipo}</b> - ${objPc.codigo} - STRIKE ${objPc.valor} <br/>`
                }
                return contentHtml
            }
            function formatDate(dateString) {
                // Cria um objeto Date a partir da string
                const date = new Date(dateString);

                // Extrai o dia, mês e ano
                const day = String(date.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda, se necessário
                const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna de 0 a 11, por isso o +1
                const year = date.getFullYear();

                // Retorna a data formatada no formato dd/mm/YYYY
                return `${day}/${month}/${year}`;
            }
            async function AddMessage(){
                if(!(strategySelected === '0' || ativoSelected === '0' || valueAtivo.length === 0 || pmValue.length === 0 || validade === '')){
                    const messageInHtml = `
                    ${strategySelected} <br/>
                    <b>${ativoSelected} - ${valueAtivo}</b><br/>
                    <b>PM GLOBAL: ${pmValue}</b><br/>
                    ${CreateHtmlPutCall()}
                    <b>VALIDADE ${formatDate(validade)}</b>
                
                    `
                    await PostCreateMessage(messageInHtml, strategySelected + ' - ' + ativoSelected)


                }
                else{
                    Swal.fire({
                        "text": "Preencha todos os campos para adicionar uma mensagem",
                        "icon": "error"
                    })
                }
            }
            function ClearFormsPutCall(){
                setCompraVenda('COMPRA')
                setTipo('')
                setCodigo('')
                setValuePutCall('')
            }

            function AddPutsCall(){
                if(tipo.length > 0 && codigo.length > 0 && valuePutCall.length > 0){
                    setArrayPutsCalls([...arrayPutsCalls, {compraVenda: compraVenda, tipo: tipo, codigo: codigo, valor: valuePutCall}])
                    ClearFormsPutCall()
                }
            }

            function PutsCallCreated({objPutCall}){
                return (
                    <div className="row mt-2">
                        <div className="col-10">
                            <button type="button" className="btn btn-outline-secondary w-100 rounded-pill">
                                <div className="col-12">
                                    {objPutCall.compraVenda} {objPutCall.tipo} - {objPutCall.codigo} - STRIKE {objPutCall.valor}
                                </div>

                            </button>
                        </div>

                        <div className="col-1 d-flex justify-content-center">
                            <button type="button" className="btn h-100 btn-outline-danger w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                                <TiDelete size={35} className=""/>
                            </button>
                        </div>
                    </div>
                );
            }

            return (
                <div className="container-fluid p-0">
                    <div className="col-12">
                        <div className="form-floating">
                            <select className="form-select" value={strategySelected}
                                    onChange={(e) => setStrategySelected(e.target.value)} id="strategyCreateMessage">
                                <option value="0">---</option>
                                {allStrategys.map((objStr, index) => (
                                    <option value={objStr.name} key={index}>{objStr.name}</option>
                                ))}
                            </select>
                            <label htmlFor="strategyCreateMessage">Estratégia</label>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-select" value={ativoSelected} onChange={(e)=>setAtivoSelected(e.target.value)} id="ativoCreateMessage">
                                    <option value="0">---</option>
                                    {allAtivos.map((objAtv, index) => (
                                        <option value={objAtv.name} key={index}>{objAtv.name}</option>
                                    ))}
                                </select>
                                <label htmlFor="ativoCreateMessage">Ativo</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" value={valueAtivo} onChange={(e)=>setValueAtivo(e.target.value)} className="form-control" id="ativoValue"/>
                                <label htmlFor="ativoValue">Valor Ativo (R$)</label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="font_color_green_home chakra-petch-regular col-6 d-flex align-items-center justify-content-end">
                            PM GLOBAL:
                        </div>

                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" value={pmValue} onChange={(e)=>setPmValue(e.target.value)} className="form-control" id="pmGlobalValue"/>
                                <label htmlFor="pmGlobalValue">Valor(R$)</label>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-2 border rounded-3 p-2">
                        <div className="col-12 text-center font_color_green_home chakra-petch-regular">
                            PUT/CALL
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-floating">
                                    <select className="form-select" onChange={(e)=>setCompraVenda(e.target.value)} value={compraVenda} id="compraVendaCreateMessage">
                                        <option value="COMPRA">COMPRA</option>
                                        <option value="VENDA">VENDA</option>
                                    </select>
                                    <label htmlFor="compraVendaCreateMessage">COMPRA/VENDA</label>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="form-floating">
                                    <select className="form-select" onChange={(e) => setTipo(e.target.value)}
                                            value={tipo} id="tipoVendaCreateMessage">
                                        <option value="COMPRA">COMPRA</option>
                                        <option value="VENDA">VENDA</option>
                                    </select>
                                    <label htmlFor="compraVendaCreateMessage">Tipo</label>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="form-floating">
                                    <input type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)}
                                           className="form-control" id="codigoCreateMessage"/>
                                    <label htmlFor="codigoCreateMessage">Código</label>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="form-floating">
                                    <input type="text" value={valuePutCall} onChange={(e)=>setValuePutCall(e.target.value)} className="form-control" id="valorCreateMessage"/>
                                    <label htmlFor="valorCreateMessage">Valor</label>
                                </div>
                            </div>

                            <div className="col-4 d-flex align-items-center">
                                <button onClick={AddPutsCall} className="btn btn_pattern rounded-4" type="button">
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 font_color_green_home chakra-petch-regular mt-2">
                    PUT/CALL Adicionados:
                    </div>
                    <div className="container_estrategias p-2 border rounded-3">
                        {arrayPutsCalls.map((objPutCall, index)=>(
                            <PutsCallCreated objPutCall={objPutCall} key={index}/>
                        ))}
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="form-floating">
                                <input type="date" value={validade}
                                       onChange={(e) => setValidade(e.target.value)} className="form-control"
                                       id="validadeCreateMessage"/>
                                <label htmlFor="validadeCreateMessage">Validade</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 my-2 d-flex justify-content-center">
                        <button className="btn btn_pattern rounded-4" onClick={AddMessage} type="button">
                            Criar Mensagem
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div className="container-fluid border rounded-3">
                <div className="row">
                    <div className="col-auto">
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setTypeMessage(e.target.value)}
                                   type="radio" name="typeMessage" value="OPÇÃO" id="opcaoCheck"/>
                            <label className="form-check-label" htmlFor="opcaoCheck">
                                OPÇÕES
                            </label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-check">
                            <input className="form-check-input" onChange={(e) => setTypeMessage(e.target.value)}
                                   type="radio" name="typeMessage" value="AÇÃO" id="acaoCheck"/>
                            <label className="form-check-label" htmlFor="acaoCheck">
                                AÇÕES
                            </label>
                        </div>
                    </div>
                </div>

                {typeMessage !== null && <ReturnContentCreateMessage/>}
            </div>

        );
    }

    function ContentStrategy() {
        const [strategyName, setStrategyName] = useState('')
        useEffect(() => {
        }, []);
        function ButtonStrategyExists({objStrategy}){
            async function DeleteStrategy(){
                await requestBackend('DELETE','/users/all_strategys/' + objStrategy.id + '/', null, csrf)
                setAllStrategys(allStrategys.filter(strat=>strat.id !== objStrategy.id))
            }
            return (
                <div className="row mt-2">
                    <div className="col-10">
                        <button type="button" className="btn btn-outline-secondary w-100 rounded-pill">
                            <div className="col-12">
                                <SiMicrostrategy size={35} className="me-2"/> {objStrategy.name}
                            </div>

                        </button>
                    </div>

                    <div className="col-1 d-flex justify-content-center">
                        <button onClick={DeleteStrategy} type="button" className="btn h-100 btn-outline-danger w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                            <TiDelete size={35} className=""/>
                        </button>
                    </div>
                </div>
            );
        }

        async function AddNewStrategy(){
            if(strategyName.length > 0){
                const request = await requestBackend('POST','/users/all_strategys/', {'name': strategyName}, csrf)
                const newId = await request['newId']
                setAllStrategys([...allStrategys, {id: newId, name: strategyName}])

            }
        }

        return (
            <div className="container-fluid border rounded-3 p-3">
                <div className="col-12">
                    <div className="form-floating">
                        <input type="text" className="form-control" onChange={(e)=>setStrategyName(e.target.value)} id="strategy"/>
                        <label htmlFor="strategy">Nome da estratégia</label>
                    </div>
                </div>
                <div className="col-12 mt-2 d-flex justify-content-end">
                    <button className="btn btn_pattern w-auto rounded-4" onClick={AddNewStrategy} type="button">
                        Adicionar
                    </button>
                </div>

                <div className="container_estrategias border rounded-3 mt-2 p-2">
                    {allStrategys.map((objSt, index)=>(
                        <ButtonStrategyExists objStrategy={objSt} key={index}/>
                    ))}
                </div>
            </div>
        );
    }

    function ContentAllMessages() {

        function CardMsgExists({objMsg}) {

            async function DeleteMsgExists() {
                await requestBackend('DELETE','/users/all_msgs/' + objMsg.id + '/', null, csrf)
                setAllMessages(allMessages.filter(msg=>msg.id.toString() !== objMsg.id.toString()))
            }
            return (
                <Toast className="d-inline-block m-1" onClose={DeleteMsgExists} show={true} bg="dark">
                    <Toast.Header>
                        <strong>
                            {objMsg.date}
                        </strong>
                    </Toast.Header>
                    <Toast.Body className="">
                        <div className="col-12"><div dangerouslySetInnerHTML={{ __html: objMsg.msg }} /></div>
                    </Toast.Body>
              </Toast>
            );
        }
        return (
            <div className="container-fluid border mt-4 rounded-3 p-3">
                {allMessages.length === 0 && (
                    <div className="col-12 font_size_lorem font_color_green_home">
                        <AiTwotoneEyeInvisible className="me-1"/> Preencha os formularios para cadastrar!!
                    </div>)}
                {allMessages.map((objMsg, index) => (
                    <CardMsgExists objMsg={objMsg} key={index}/>
                ))}
            </div>
        );
    }

    function ContentAtivos(){
        const [ativoName, setAtivoName] = useState('')
        useEffect(() => {

        }, []);
        function ButtonAtivoExists({objAtivo}){
            async function DeleteAtivo(){
                await requestBackend('DELETE','/users/all_ativos_msg/' + objAtivo.id + '/', null, csrf)
                setAllAtivos(allAtivos.filter(atv=>atv.id !== objAtivo.id))
            }
            return (
                <div className="row mt-2">
                    <div className="col-10">
                        <button type="button" className="btn btn-outline-secondary w-100 rounded-pill">
                            <div className="col-12">
                                <SiMicrostrategy size={35} className="me-2"/> {objAtivo.name}
                            </div>

                        </button>
                    </div>

                    <div className="col-1 d-flex justify-content-center">
                        <button onClick={DeleteAtivo} type="button" className="btn h-100 btn-outline-danger w-auto rounded-circle text-start d-inline-flex align-items-center p-1">
                            <TiDelete size={35} className=""/>
                        </button>
                    </div>
                </div>
            );
        }

        async function AddNewAtivo(){
            if(ativoName.length > 0){
                const request = await requestBackend('POST','/users/all_ativos_msg/', {'name': ativoName}, csrf)
                const newId = await request['newId']
                setAllAtivos([...allAtivos, {id: newId, name: ativoName}])

            }
        }

        return (
            <div className="container-fluid border rounded-3 p-3">
                <div className="col-12">
                    <div className="form-floating">
                        <input type="text" value={ativoName} className="form-control" onChange={(e)=>setAtivoName(e.target.value)} id="ativo"/>
                        <label htmlFor="ativo">Nome do ativo</label>
                    </div>
                </div>
                <div className="col-12 mt-2 d-flex justify-content-end">
                    <button className="btn btn_pattern w-auto rounded-4" onClick={AddNewAtivo} type="button">
                        Adicionar
                    </button>
                </div>

                <div className="container_estrategias border rounded-3 mt-2 p-2">
                    {allAtivos.map((objAtivo, index)=>(
                        <ButtonAtivoExists objAtivo={objAtivo} key={index}/>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="row">
                <div className="col-6">
                    <div
                        className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                        Criação de Estratégias
                    </div>
                    <ContentStrategy/>

                    <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                        Criação de Ativos
                    </div>
                    <ContentAtivos/>
                </div>

                <div className="col-6">
                    <div
                        className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                    Monte a mensagem!
                    </div>
                    <RowCreateNewMsg/>
                    <ContentAllMessages/>
                </div>
            </div>

        </div>
    );
}

export default MessagesAcoes