import { Modal } from 'react-bootstrap';
import React, {useEffect, useState} from "react";
import {requestBackend} from "../Home/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import Swal from "sweetalert2";
function ModalPlanUser({modalShow, setModalShow, idUserSelected, setIdUserSelected, csrf}){
    const [plaUser, setPlanUser] = useState('0')
    const [dateStartPlanUser, setDateStartPlanUser] = useState('')
    const [dateFinishPlanUser, setDateFinishPlanUser] = useState('')
    const [planActive, setPlanActive] = useState(false)

    useEffect(() => {
        async function getDatasPlan() {
            const request = await requestBackend('GET', '/users/gerenciar_plan_user/' + idUserSelected + '/', null, csrf)
            const objDatas = await request['datasPlan']
            setPlanUser(objDatas.plan_user)
            setDateStartPlanUser(objDatas.date_start)
            setDateFinishPlanUser(objDatas.date_finish)
            setPlanActive(objDatas.active)
        }

        if(idUserSelected !== null){
            getDatasPlan()
        }
        else{
            setPlanUser('0')
            setDateStartPlanUser('')
            setDateFinishPlanUser('')
            setPlanActive(false)
        }
    }, [idUserSelected]);
    function CloseModal(){
        setIdUserSelected(null)
        setModalShow(false)
    }

    function StateOk(state){
        return !(state === '0' || state === '')
    }

    function VerifyDatasSend(){
        const objVerify = {'Plano': StateOk(plaUser),
                                'Data Inicio': StateOk(dateStartPlanUser),
                                'Data Fim': StateOk(dateFinishPlanUser)
        }
        let arrayInvalids = []
        for(const form in objVerify){
            if(!objVerify[form]){
                arrayInvalids.push(form)
            }
        }
        if(arrayInvalids.length === 0){
            return true
        }
        else{
            arrayInvalids.join(', ')
        }
    }
    async function SendDatasPlan(){
        const validationForms = VerifyDatasSend()
        if(validationForms){
            await requestBackend('POST',
                '/users/gerenciar_plan_user/', {idUser: idUserSelected,
                    plan: plaUser, dateStart: dateStartPlanUser,
                    dateFinish: dateFinishPlanUser, active: planActive}, csrf)
            Swal.fire({
                text: 'Plano salvo com sucesso!',
                icon: "success"
            })

        }
        else{
            Swal.fire({
                text: 'Formulários inválidos: ' + validationForms,
                icon: "error"
            })
        }
    }
    return (
        <Modal size="lg" show={modalShow} onHide={CloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Plano</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="row">
                  <div className="col-12">
                      <div className="form-floating">
                          <select className="form-select" value={plaUser} id="planUser" onChange={(e)=>setPlanUser(e.target.value)}>
                              <option value="0">---</option>
                              <option value="PLANO 1">SuperQuant Full</option>
                              <option value="PLANO 2">SuperQuant Extended</option>
                          </select>
                          <label htmlFor="planUser">Plano</label>
                      </div>
                  </div>

                  <div className="col-6 mt-2">
                      <form className="form-floating">
                          <input type="date" className="form-control" id="dateInit" value={dateStartPlanUser} onChange={(e)=>setDateStartPlanUser(e.target.value)}/>
                          <label htmlFor="dateInit">Data Inicio</label>
                      </form>
                  </div>

                  <div className="col-6 mt-2">
                      <form className="form-floating">
                          <input type="date" className="form-control" id="dateFinish" value={dateFinishPlanUser}
                                 onChange={(e) => setDateFinishPlanUser(e.target.value)}/>
                          <label htmlFor="dateFinish">Data Fim</label>
                      </form>
                  </div>

                  <div className="col-12 mt-1 d-flex justify-content-end">
                      <div className="form-check">
                          <input className="form-check-input" checked={planActive} onChange={(e)=>setPlanActive(e.target.checked)} type="checkbox" id="checkPlanActive"/>
                          <label className="form-check-label" htmlFor="checkPlanActive">
                              Plano Ativo
                          </label>
                      </div>
                  </div>

                  <div className="col-12">
                      <button className="btn btn_pattern fw-bold px-4 float-end mt-1" onClick={SendDatasPlan}>
                          Salvar <FontAwesomeIcon icon={faRightToBracket}/>
                      </button>
                  </div>
              </div>
          </Modal.Body>
        </Modal>
    );
}

export default ModalPlanUser