import React, {useEffect, useState} from "react";
import {requestBackend} from "../Home/utils";
import Toast from "react-bootstrap/Toast";
import {AiTwotoneEyeInvisible} from "react-icons/ai";

function ContatoMessages({csrf}){
    const [allMessages, setAllMessages] = useState([])

    useEffect(() => {
         async function getMsg(){
            const request = await requestBackend('GET','/users/messages_contato/', null, csrf)
            setAllMessages(await request['messages'])
        }
        getMsg()
    }, []);

    function CardMsgExists({objMsg}){

        async function DeleteMsgExists(){
            await requestBackend('DELETE','/users/messages_contato/' + objMsg.id + '/', null, csrf)
            setAllMessages(allMessages.filter(msg=>msg.id.toString() !== objMsg.id.toString()))
        }
        return (
            <Toast className="d-inline-block m-1" onClose={DeleteMsgExists} show={true} bg="dark">
                <Toast.Header>
                    <strong>
                        {objMsg.date}
                    </strong>
                </Toast.Header>
                <Toast.Body className="">
                    <div className="col-12">Nome: {objMsg.name}</div>
                    <div className="col-12 mt-1">Telefone: {objMsg.fone}</div>
                    <div className="col-12 mt-1">Email: {objMsg.email}</div>
                    <div className="col-12 mt-1">Mensagem: {objMsg.message}</div>
                </Toast.Body>
          </Toast>
        );
    }
    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Mensagens de contato
            </div>
            <div className="container-fluid border mt-4 rounded-3 p-3">
                {allMessages.length === 0 && (
                    <div className="col-12 font_size_lorem font_color_green_home">
                        <AiTwotoneEyeInvisible className="me-1"/> Nenhuma mensagem para visualizar!!
                    </div>)}
                {allMessages.map((objMsg, index) => (
                    <CardMsgExists objMsg={objMsg} key={index}/>
                ))}
            </div>
        </div>
    );
}

export default ContatoMessages