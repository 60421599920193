import React, {useEffect, useState} from "react";
import imgHome2 from '../../img_react/bg_home_2.png';
import imgPlan1 from '../../img_react/plan1.png';
import imgPlan2 from '../../img_react/plan2.png';
import imgHome from '../../img_react/img_home.png';
import {requestBackend} from "./utils";
import Footerzada from "./Footerzada";

function ContainerBaseHome({csrf, setMenu}){
    document.title = 'SuperQuant'
    const [objPlan1, setObjPlan1] = useState({price_real: 0, price_promo: 0})
    const [objPlan2, setObjPlan2] = useState({price_real: 0, price_promo: 0})

    useEffect(() => {
        async function getPlans(){
            const request = await requestBackend('GET','/users/return_main_plans/', null, csrf)
            const plans = await request['plans']
            setObjPlan1(plans['1'])
            setObjPlan2(plans['2'])
        }
        getPlans()
    }, []);
    function ContentHome(){

        function About(){
            return (
                <div className="container">
                    <div className="row h-100">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12" style={{height: "60vh"}}>
                            <div className="container-fluid position-relative">
                                <img src={imgHome} className="position-absolute mt-5"
                                     style={{left: '-20vw', width: '170%'}}/>
                            </div>
                        </div>

                        <div
                            className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center height_content">

                            <div className="container-fluid">
                                <div className="col-12 font_color_green_home chakra-petch-regular fw-bold font_size_headers_in_content_home">
                                    SOBRE A PLATAFORMA
                                </div>

                                <div className="col-12 text-white font_size_lorem mt-2 roboto-regular" style={{textAlign: "justify"}}>

                                    No dinâmico mundo dos investimentos, tomar decisões assertivas é a chave para o
                                    sucesso. Mas como saber para qual lado o vento está soprando? Com o <b>SuperQuant</b>, você
                                    terá acesso à análise em tempo real da movimentação dos players globais, os grandes
                                    investidores que ditam o ritmo do mercado.
                                    <br/>
                                    <br/>

                                    Entenda o jogo e faça jogadas inteligentes com o <b>SuperQuant</b>:
                                    <br/>
                                    <br/>
                                    • Acompanhe as ações dos players em tempo real: monitore cada passo dos grandes
                                    investidores e identifique suas estratégias.
                                    <br/>
                                    • Descubra as tendências antes que todos: antecipe as oscilações do mercado e tome
                                    decisões de compra e venda com mais precisão.
                                    <br/>
                                    • Negocie com base em informações confiáveis: utilize dados concretos e análises
                                    precisas para embasar suas decisões de investimento.
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            );
        }

        function Why(){
            return (
                <div className="container-fluid font_calibri d-flex align-items-center">
                    <div className="container text-white mt-4">
                        <div className="col-12 fw-bold font_size_headers_in_content_home text-center chakra-petch-regular">
                            POR QUE USAR O SUPER QUANT?
                        </div>

                        <div className="row d-flex justify-content-around mt-4 roboto-regular">
                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Rentabilidade
                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Invista com mais segurança, minimize seus riscos e maximize seus lucros com base em dados concretos.
                                    <br/>
                                    O <b>SuperQuant</b> vasculha o mercado em busca de oportunidades de investimento com alto potencial de retorno, utilizando algoritmos avançados e inteligência artificial. Isso significa que você não precisa perder tempo pesquisando manualmente, pois o <b>SuperQuant</b> faz o trabalho duro para você.
                                </div>

                            </div>

                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Segurança

                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Obtenha uma visão abrangente do mercado global e identifique as oportunidades mais promissoras.
                                    <br/>
                                    O <b>SuperQuant</b> utiliza técnicas avançadas de análise de big data para detectar tendências emergentes antes que se tornem evidentes para a maioria dos investidores, permitindo que você se posicione estrategicamente antes da valorização dos preços. Além disso, nosso sistema oferece insights personalizados para ajudá-lo a tomar decisões mais seguras.
                                </div>
                            </div>

                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Inteligência
                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Tome decisões mais inteligentes, faça escolhas assertivas e consistentes com base em análises precisas e atualizadas.
                                    <br/>
                                    O <b>SuperQuant</b> monitora continuamente seus investimentos e o alerta sobre quaisquer eventos ou mudanças que possam afetar seus resultados. Isso garante que você esteja sempre no controle de seus investimentos e possa tomar as medidas necessárias para proteger seu patrimônio.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        function App(){
            return (
                <div className="container mt-5">
                    <div className="row d-flex align-items-stretch">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="container-fluid h-100 d-flex align-items-center">
                                <div className="container-fluid">
                                    <div
                                        className="col-12 chakra-petch-regular font_color_green_home fw-bold font_size_headers_in_content_home chakra-petch-regular">
                                        NOSSO APLICATIVO
                                    </div>

                                    <div className="col-12 text-white font_size_lorem mt-2 roboto-regular" style={{textAlign: "justify"}}>
                                        Invista com inteligência e posicione-se estrategicamente para maximizar seus retornos.
                                        <br/>
                                        Proteja seu patrimônio e tenha a certeza de estar no caminho certo para realizar seus sonhos. O <b>SuperQuant</b> oferece análises detalhadas e insights precisos para garantir que seu futuro financeiro esteja bem encaminhado.

                                        <br/>
                                        O <b>SuperQuant</b> monitora indicadores econômicos, notícias e eventos, proporcionando uma visão completa do mercado global.
                                        <br/>
                                        Garanta maior tranquilidade e qualidade de vida com investimentos seguros e rentáveis. Com o <b>SuperQuant</b>, você tem a ferramenta ideal para alcançar seus objetivos financeiros com eficiência.
                                        <br/>
                                        Baixe o aplicativo agora e transforme seu futuro financeiro.
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                            <img src={imgHome2} style={{width: '120%'}}/>
                        </div>


                    </div>
                </div>
            );
        }

        function Plans() {
            return (
                <div className="container chakra-petch-regular mt-5">
                    <div className="w-100 d-flex align-items-center">
                        <div className="container-fluid text-white">
                            <div className="col-12 fw-bold mt-4 font_size_headers_in_content_home text-center">
                                NOSSOS PLANOS
                            </div>

                            <div className="container mt-3">
                                <div className="row boder_homezinho_2 d-flex"
                                     style={{backgroundImage: `url(${imgPlan1})`, backgroundSize: "cover"}}>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 h-100">

                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 h-100 bg_green_pattern_home px-3" style={{flex: `1`}}>
                                        <div className="col-12 fw-bold mt-2 font_size_headers_in_content_home">
                                            SuperQuant <br/> Full
                                        </div>
                                        <>
                                            <div
                                                className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                            </div>

                                            <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                                R${objPlan1.price_real.toFixed(2).replace('.', ',')}
                                            </div>

                                            <div className="col-12 my-2">
                                                <button type="button" onClick={() => setMenu('Assine')}
                                                        className="btn btn_assinar text-black fw-bold">
                                                    ASSINAR
                                                </button>
                                            </div>
                                        </>


                                    </div>
                                </div>

                                <div className="row mt-5 d-flex boder_homezinho_2"
                                     style={{backgroundImage: `url(${imgPlan2})`, backgroundSize: "cover"}}>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 h-100">

                                    </div>

                                    <div
                                        className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 h-100 bg_green_pattern_home px-3">
                                        <div className="col-12 fw-bold mt-2 font_size_headers_in_content_home">
                                            SuperQuant <br/> Extended
                                        </div>
                                        <>
                                            <div className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                            </div>

                                            <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                                R${objPlan2.price_real.toFixed(2).replace('.', ',')}
                                            </div>

                                            <div className="col-12 my-2">
                                                <button type="button" onClick={()=>setMenu('Assine')}
                                                        className="btn btn_assinar text-black fw-bold">
                                                    ASSINAR
                                                </button>
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <About/>
                <Why/>
                <Plans/>
                <App/>
                <Footerzada/>
            </div>
        );
    }

    return (
        <ContentHome/>
    );
}

export default ContainerBaseHome