import logoSQ from '../../img_react/logo_admin_sis.svg'
import imgFundo from '../../img_react/fundos_dash.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import React, {useEffect, useState} from "react";
import { FaUsers, FaListUl, FaMoneyBillAlt } from "react-icons/fa";
import { BsHouse } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GrConfigure } from "react-icons/gr";
import {getCsrfToken, getMenuSelected, requestBackend, userLogged} from "../Home/utils";
import { MdOutlineCastForEducation } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";
import { SiGooglemessages } from "react-icons/si";
import { FaYoutube } from "react-icons/fa";
import AllUsers from "./Users";
import Education from "./Edu";
import Twitter from "./Twitter";
import MessagesAcoes from "./Messages";
import Planos from "./Plans";
import ContatoMessages from "./ContatoMessages";
import LinkYtb from "./LinkYtb";
function GerenciamentoUsers(){
    let csrfToken = null
    const [menuSelected, setMenuSelected] = useState('Users')

    function ReturnClassActive(menu){
        return menuSelected === menu ? 'active' : ''
    }

    document.title = 'Administração'
    useEffect(()=> {
        const getCsrf = async () => {
            csrfToken = await getCsrfToken()
        }

        const acessInSis = async () => {
            const requestAcess = await requestBackend('GET', '/users/verify_acess_in_admin_sis/', null, csrfToken)
            const acessReturned = await requestAcess['acess']
            if(!acessReturned){
                window.location.href = '/'
            }
        }

        getCsrf()
        acessInSis()
    }, [])

    function ReturnContent(){
        if(menuSelected === 'Edu'){
            return <Education csrf={csrfToken}/>
        }
        else if(menuSelected === 'Twitter'){
            return <Twitter csrf={csrfToken}/>
        }
        else if(menuSelected === 'Msg'){
            return <MessagesAcoes csrf={csrfToken}/>
        }
        else if(menuSelected === 'Planos'){
            return <Planos csrf={csrfToken}/>
        }
        else if(menuSelected === 'Contato'){
            return <ContatoMessages csrf={csrfToken}/>
        }
        else if(menuSelected === 'LinkYtb'){
            return <LinkYtb csrf={csrfToken}/>
        }
        else{
            return <AllUsers csrf={csrfToken}/>
        }
    }
    function NavMenu(){
        return (
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1"
                 id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header">
                    <img src={logoSQ} width={80}/>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body chakra-petch-regular">
                    <ul className="nav flex-column mt-2">
                        <li className="nav-item cursor_pointer" title="Home">
                            <a className="nav-link text-black fw-bold" aria-current="page" href="/"><BsHouse size={30}
                                                                                                             className="me-1"/> Site</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Usuarios"
                            onClick={() => setMenuSelected('Users')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Users')}`}
                               aria-current="page"><FaUsers size={30}
                                                            className="me-1"/> Usuários</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Conteudo Edu"
                            onClick={() => setMenuSelected('Edu')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Edu')}`}
                               aria-current="page"><MdOutlineCastForEducation size={30} className="me-1"/> Conteudo
                                Educacional</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Twitter"
                            onClick={() => setMenuSelected('Twitter')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Twitter')}`}
                               aria-current="page"><FaSquareXTwitter size={30} className="me-1"/> Twitter</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Planos"
                            onClick={() => setMenuSelected('Planos')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Planos')}`}
                               aria-current="page"><FaMoneyBillAlt size={30} className="me-1"/> Planos</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Msg"
                            onClick={() => setMenuSelected('Msg')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Msg')}`}
                               aria-current="page"><SiGooglemessages size={30} className="me-1"/> Mensagens no Dash
                                Ações</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Contato"
                            onClick={() => setMenuSelected('Contato')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('Contato')}`}
                               aria-current="page"><MdContactPhone size={30} className="me-1"/> Mensagens de Contato</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="LinkYtb"
                            onClick={() => setMenuSelected('LinkYtb')}>
                            <a className={`nav-link text-black fw-bold ${ReturnClassActive('LinkYtb')}`}
                               aria-current="page"><FaYoutube size={30} className="me-1"/> Url Youtube no Dash de Ações</a>
                        </li>

                        <li className="nav-item cursor_pointer" title="Admin Metatrader">
                            <a className="nav-link text-black fw-bold" aria-current="page" href="/AdminMt/"><GrConfigure
                                size={30} className="me-1"/>Admin Metatrader</a>
                        </li>


                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className="position-absolute h-100 w-100 bg_login overflow-x-none"
             style={{backgroundImage: `url(${imgFundo})`, backgroundSize: "cover"}}>
            <div className="row h-100">

                <div className="container-fluid d-flex align-items-center justify-content-center h-100">
                    <div className="content_gerenciamento_sis rounded-4 text-white">
                        <button className="btn btn_pattern w-auto rounded-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <FaListUl size={30}/>
                        </button>
                        <div className="content_contents_return_ger">
                            <ReturnContent/>
                        </div>

                    </div>
                </div>

            </div>
            <NavMenu/>
        </div>

    );
}

export default GerenciamentoUsers

