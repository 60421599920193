import React, {useState, useEffect} from "react";
import imgPlan1 from "../../img_react/assine_plano1.png";
import imgPlan2 from "../../img_react/assine_plano2.png";
import {requestBackend} from "./utils";
import {faBackward} from "@fortawesome/free-solid-svg-icons/faBackward";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "./utils";
import Footerzada from "./Footerzada";

function Assine({csrf, setMenu, userLogged}){
    const [tipoPlanSelected, setTipoPlanSelected] = useState(null)

    function ReturnContentAssine(){
        if(tipoPlanSelected === null){
            return <ContentNotPlanSelected/>
        }
        else{
            return <ContentPlanSelected/>
        }

    }

    function RealNamePlan(plan){
        return plan === 'PLANO 1' ? 'SuperQuant Full' : 'SuperQuant Extended'
    }

    function ContentPlanSelected() {
        const [allPlansInTypePlan, setAllPlansInTypePlan] = useState([])
        useEffect(() => {
            async function getPlans() {
                const request = await requestBackend('GET', '/users/return_plans_in_plan/', {planSelected: tipoPlanSelected}, csrf)
                const plans = await request['plans']
                setAllPlansInTypePlan(plans)
            }

            getPlans()
        }, []);
        function CardOptionPlan({objPlan}){
            async function SelectPlanAssign(){
                if(userLogged){
                    const request = await requestBackend('GET', '/users/create_url_payment/' + objPlan.id + '/', null, csrf)
                    window.location.href = await request['url']
                }
                else{
                    setMenu('Login')
                }
            }
            return (
                <div
                    className="col-12 rounded-3 bg_green_pattern_home mt-3 font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                    <div className="row d-flex justify-content-between my-1">
                        <div
                            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 d-flex align-items-center">
                            <div className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                {objPlan.name}
                            </div>
                        </div>
                        {objPlan.price_promo !== null ? (
                            <>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                        R${objPlan.price_promo.toFixed(2).replace('.', ',')}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                        R${objPlan.price_real.toFixed(2).replace('.', ',')}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                            <button type="button" onClick={SelectPlanAssign} className="btn btn_assinar text-black fw-bold">
                                ASSINAR
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-12 mt-5 text-white chakra-petch-regular fw-bold font_size_mini_headers_in_content_home">
                    Escolha o período de sua assinatura para o {RealNamePlan(tipoPlanSelected)}
                </div>

                {allPlansInTypePlan.map((objPlan, index) => (
                    <CardOptionPlan objPlan={objPlan} key={index}/>
                ))}

                <div className="col-12 mt-3">
                    <button className="btn btn_pattern" onClick={()=>setTipoPlanSelected(null)}>
                        <FontAwesomeIcon icon={faBackward} className="me-2"/>Voltar
                    </button>
                </div>
            </div>
        );
    }

    function ContentNotPlanSelected() {
        function BlockPlan1() {
            return (
                <div>
                    <div className="col-12 font_size_headers_in_content_home chakra-petch-regular text-center mt-5">
                        SuperQuant Full
                    </div>
                    <div className="container-fluid boder_homezinho_2 d-flex align-items-end p-0 mb-1"
                         style={{
                             backgroundImage: `url(${imgPlan1})`,
                             backgroundSize: "cover",
                             height: "450px"
                         }}>
                        <div
                            className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                            <div className="row d-flex justify-content-center">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-center py-3">
                                    <button type="button" onClick={()=>setTipoPlanSelected('PLANO 1')} className="btn btn_assinar p-3 text-black fw-bold">
                                        ASSINAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 font_size_about_plan text-center roboto-regular mt-2 px-3">
                        Indicador SuperQuant<br/>Indicador GPO<br/>Indicador Strike Finder<br/>Dashboard - Índice e Dólar<br/>Dashboard - Ações / Mercados Internacionais
                    </div>
                </div>
            );
        }

        function BlockPlan2() {
            return (
                <div>
                    <div className="col-12 chakra-petch-regular font_size_headers_in_content_home text-center mt-5">
                        SuperQuant Extended
                    </div>
                    <div className="container-fluid boder_homezinho_2 d-flex align-items-end p-0 mb-1"
                         style={{
                             backgroundImage: `url(${imgPlan2})`,
                             backgroundSize: "cover",
                             height: "450px"
                         }}>
                        <div
                            className="col-12 chakra-petch-regular bg_green_pattern_home font_size_mini_headers_in_content_home text-center">
                            <div className="row d-flex justify-content-center">

                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-center py-3">
                                    <button type="button" onClick={()=>setTipoPlanSelected('PLANO 2')} className="btn btn_assinar p-3 text-black fw-bold">
                                        ASSINAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 font_size_about_plan text-center mt-2 px-3 roboto-regular">
                        Indicador SuperQuant - Vitalício<br/>Indicador GPO  - Vitalício<br/>Indicador Strike Finder - Vitalício<br/>Dashboard - Índice e Dólar<br/>Dashboard - Ações / Mercados Internacionais
                    </div>
                </div>
            );
        }
        return (
            <div className="row d-flex justify-content-between">
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                    <BlockPlan1/>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                    <BlockPlan2/>
                </div>

            </div>
        );
    }


    return (
        <>
            <div className="container-fluid height_content margin_top_100">
                <div className="container h-100 text-white">
                    <ReturnContentAssine/>
                </div>
            </div>
            <Footerzada/>
        </>

    );

}

export default Assine